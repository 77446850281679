<template>
  <Chart
      :size="{ width: 1400, height: 400 }"
      :data="data"
      :margin="margin"
      :direction="direction"
      :axis="axis">

    <template #layers>
      <Grid strokeDasharray="2,2" />
      <Line :dataKeys="['name', 'up']" :lineStyle="{ stroke: 'gray' }" type="normal" />
      <Line :dataKeys="['name', 'pl']" :lineStyle="{ stroke: 'blue' }" type="natural" />
      <Line :dataKeys="['name', 'avg']" :lineStyle="{ stroke: 'red' }" type="natural" />
      <Line :dataKeys="['name', 'inc']" :lineStyle="{ stroke: 'green' }" type="natural" />
      <Bar :dataKeys="['name', 'pl']" :maxWidth="50" :barStyle="{ fill: 'blue' }" />
      <Bar :dataKeys="['name', 'avg']" :maxWidth="50" :barStyle="{ fill: 'red' }" />
      <Bar :dataKeys="['name', 'inc']" :maxWidth="50" :barStyle="{ fill: 'green' }" />
    </template>

    <template #widgets>
      <Tooltip
          borderColor="#48CAE4"
          :config="{
          pl: { color: '#90e0ef' },
          avg: { color: '#0096c7' },
          inc: { color: '#48cae4' }
        }"
      />
    </template>
  </Chart>
</template>

<script setup>
import { ref } from 'vue'
import { Chart, Grid, Line, Tooltip, Bar, Marker} from 'vue3-charts'

const plByMonth = [
  { name: 'Jan', pl: 1000, avg: 500, inc: 300, up: 1400 },
  { name: 'Feb', pl: 2000, avg: 900, inc: 400, up: 2200 },
  { name: 'Apr', pl: 400, avg: 400, inc: 500, up: 800 },
  { name: 'Mar', pl: 2100, avg: 1300, inc: 700, up: 3850 },
  { name: 'May', pl: 200, avg: 100, inc: 200, up: 430 },
  { name: 'Jun', pl: 600, avg: 400, inc: 300, up: 940 },
  { name: 'Jul', pl: 500, avg: 90, inc: 100, up: 750 }
]
const data = ref(plByMonth)
const direction = ref('horizontal')
const margin = ref({
  left: 0,
  top: 20,
  right: 20,
  bottom: 0
})
const axis = ref({
  primary: {
    type: 'band'
  },
  secondary: {
    domain: ['dataMin', 'dataMax + 200'],
    type: 'linear',
    ticks: 8
  }
})
</script>
<style>
</style>
